@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  font-display: swap; /* Uses fallback font, while waiting for font to load */
}

html,
body {
  position: fixed;
  overflow: hidden;
  width: 100%;
}

body {
  font-family: "Open Sans", sans-serif;
  position: relative;
  -webkit-user-select: none;
          user-select: none;
}

h1 {
  font-size: 1.25rem;
  font-weight: 400;
}

h2 {
  font-size: 1.125rem;
  font-weight: 700;
}

p.content {
  font-size: 1rem;
  font-weight: 400;
}

div.meta,
p.meta,
a.meta {
  font-size: 0.8125rem;
  font-weight: 400;
}

a {
  text-decoration: none;
}

.bold {
  font-weight: 700;
}

.text-center {
  text-align: center;
}

.page-forward-enter,
.page-forward-enter-active,
.page-forward-exit,
.page-forward-exit-active,
.page-backward-enter,
.page-backward-enter-active,
.page-backward-exit,
.page-backward-exit-active {
  transition-delay: 50ms;
  transition: opacity 120ms ease, -webkit-transform 320ms ease;
  transition: transform 320ms ease, opacity 120ms ease;
  transition: transform 320ms ease, opacity 120ms ease, -webkit-transform 320ms ease;
  position: absolute;
  top: 0;
  will-change: auto;
}

.page-forward-enter,
.page-forward-enter-active {
  z-index: 4;
}

.page-forward-exit,
.page-forward-exit-active {
  z-index: 3;
}

@media screen and (max-width: 1024px) {
  .page-forward-enter {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }

  .page-forward-enter-active {
    -webkit-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0);
  }

  .page-forward-exit {
    -webkit-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0);
  }

  .page-forward-exit-active {
    -webkit-transform: translate3d(-40%, 0, 0);
            transform: translate3d(-40%, 0, 0);
  }
}

@media screen and (min-width: 1025px) {
  .page-forward-enter {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
    opacity: 0;
  }

  .page-forward-enter-active {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    opacity: 1;
  }
}

/* Page transition backward */

.page-backward-enter,
.page-backward-enter-active {
  z-index: 3;
}

.page-backward-exit,
.page-backward-exit-active {
  z-index: 4;
}

@media screen and (max-width: 1024px) {
  .page-backward-enter {
    -webkit-transform: translate3d(-40%, 0, 0);
            transform: translate3d(-40%, 0, 0);
  }

  .page-backward-enter-active {
    -webkit-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0);
  }

  .page-backward-exit {
    -webkit-transform: translate3d(0%, 0px, 0);
            transform: translate3d(0%, 0px, 0);
  }

  .page-backward-exit-active {
    -webkit-transform: translate3d(100%, 0px, 0);
            transform: translate3d(100%, 0px, 0);
  }
}

@media screen and (min-width: 1025px) {
  .page-backward-enter {
    -webkit-transform: translate3d(0px, 0, 0);
            transform: translate3d(0px, 0, 0);
  }

  .page-backward-enter-active {
    -webkit-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0);
  }

  .page-backward-exit {
    -webkit-transform: translate3d(0%, 0px, 0);
            transform: translate3d(0%, 0px, 0);
    opacity: 1;
  }

  .page-backward-exit-active {
    -webkit-transform: translate3d(100px, 0px, 0);
            transform: translate3d(100px, 0px, 0);
    opacity: 0;
    transition: opacity 320ms ease, -webkit-transform 320ms ease;
    transition: transform 320ms ease, opacity 320ms ease;
    transition: transform 320ms ease, opacity 320ms ease, -webkit-transform 320ms ease;
    transition-delay: 50ms;
  }
}

/* 
This file is needed becauce the default behavior of having the body set to `position: fixed;` is very helpful in fullscreen mode
while making it almost impossible to implement any sort of scrolling behaviour on iOS devices when not in fullscreen mode.

Since a css-in-js style is designed not to overrule styles, i couldn't find a proper way to overrule the style applied to body and html
fount in `base-styles.css` without creating a new stylesheet. This style sheet is only referenced from InstallationFlow.jsx 
- Jens H

*/

html,
body {
  position: relative !important;
  overflow: auto !important;
}

.contact-it-support-modal .ant-modal-header{
    padding: 0;
}
