@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  font-display: swap; /* Uses fallback font, while waiting for font to load */
}

html,
body {
  position: fixed;
  overflow: hidden;
  width: 100%;
}

body {
  font-family: "Open Sans", sans-serif;
  position: relative;
  user-select: none;
}

h1 {
  font-size: 1.25rem;
  font-weight: 400;
}

h2 {
  font-size: 1.125rem;
  font-weight: 700;
}

p.content {
  font-size: 1rem;
  font-weight: 400;
}

div.meta,
p.meta,
a.meta {
  font-size: 0.8125rem;
  font-weight: 400;
}

a {
  text-decoration: none;
}

.bold {
  font-weight: 700;
}

.text-center {
  text-align: center;
}
